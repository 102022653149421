import { Observable } from 'rxjs';
import {
    RecoverPasswordResetPayload,
    RecoverPasswordResetResponse,
} from '../dto/recover.dto';
import {
    ValidatePasswordResetPayload,
    ValidatePasswordResetResponse,
} from '../dto/validate.dto';
import {
    ResetPasswordResetPayload,
    ResetPasswordResetResponse,
} from '../dto/reset.dto';
import {
    UpdatePasswordResetPayload,
    UpdatePasswordResetResponse,
} from '../dto/update.dto';

export abstract class PasswordResetRepository {
    abstract recover(
        payload: RecoverPasswordResetPayload,
    ): Observable<RecoverPasswordResetResponse>;

    abstract validate(
        payload: ValidatePasswordResetPayload,
    ): Observable<ValidatePasswordResetResponse>;

    abstract reset(
        payload: ResetPasswordResetPayload,
    ): Observable<ResetPasswordResetResponse>;

    abstract update(
        payload: UpdatePasswordResetPayload,
    ): Observable<UpdatePasswordResetResponse>;
}
